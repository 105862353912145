import { getInstance } from '@/auth/authWrapper'
import cfg from '@root/config'
import { ApiWrapper, Connector } from '../../types/main'
import { getError } from '../../utils'

export async function login(connector: Connector, credentials: { apiKey: string; user: string }) {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/sipuni/login`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId: connector.connectorId,
            credentials,
        }),
    }
    const res = await fetch(url, options)
    if (res.status === 200) {
        return true
    } else {
        return false
    }
}

export async function getUsersList(
    connectorId: string
): Promise<ApiWrapper<{ dhUsers: any[]; megaphoneUsers: any[] } | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/sipuni/staff?connectorId=${connectorId}`
    const options = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
export async function makeCall({
    connectorId,
    phone,
    sipExtensionUserId,
}: {
    connectorId: string
    phone: string
    sipExtensionUserId?: string
}): Promise<ApiWrapper<{} | null>> {
    const auth = getInstance()
    const token = await auth.getTokenSilently()
    const url = `${cfg.apiEndpoint}/ipTelephony/sipuni/makeCall`
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
            connectorId,
            callTo: `+${phone}`,
            userId: auth.user.sub,
            sipExtensionUserId,
        }),
    }
    const res = await fetch(url.toString(), options)
    const error = await getError(res)
    const data = await res.json()
    return { data, error: error }
}
